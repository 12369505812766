document.addEventListener("alpine:init", () => {
    Alpine.data("headerSearch", () => ({
        search: '',
        showContent: false,
        result: [],
        debounceTimer: null,
        fetchData() {
            if (this.search.trim() !== '') {
                axios.post('/search/autocomplete', {
                    name: this.search
                })
                    .then(response => {
                        this.result = response.data;
                    })
                    .catch(error => {
                        console.error('Error fetching data:', error);
                    })
            } else {
                this.result = '';
                this.showContent = false;
            }
        },
        debounceSearch() {
            clearTimeout(this.debounceTimer);
            this.debounceTimer = setTimeout(() => {
                this.fetchData();
            }, 300);
        }
    }));
});
