// import Swiper JS
import Swiper from "swiper";
import { Navigation, Pagination, Thumbs } from "swiper/modules";
// import Swiper and modules styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

// Use Navigation Arrows
Swiper.use([Navigation, Pagination, Thumbs]);

// Home page - our recommendations
new Swiper(".recommendation__product .swiper-container", {
  slidesPerView: 1,
  spaceBetween: 12,
  initialSlide: 1,

  // Allows to set different parameter for different responsive breakpoints (screen sizes)
  breakpoints: {
    // // when window width is >= 360px
    360: {
      slidesPerView: 1.6,
      spaceBetween: 20,
      centeredSlides: false,
      initialSlide: 1,
    },
    // when window width is >= 576px
    576: {
      slidesPerView: 3,
      spaceBetween: 20,
      centeredSlides: false,
      initialSlide: 1,
    },
  },
});

// Home page - cheapest offers
new Swiper(".cheapest__product .swiper-container", {
  slidesPerView: 1,
  spaceBetween: 12,
  initialSlide: 1,

  // Allows to set different parameter for different responsive breakpoints (screen sizes)
  breakpoints: {
    // // when window width is >= 360px
    360: {
      slidesPerView: 1.6,
      spaceBetween: 20,
      centeredSlides: false,
      initialSlide: 1,
    },
    // when window width is >= 576px
    576: {
      slidesPerView: 3,
      spaceBetween: 20,
      centeredSlides: false,
      initialSlide: 1,
    },
  },
});

// Mobile Detail page - our recommendations
new Swiper(".mobile__details__recommendation__product .swiper-container", {
  slidesPerView: 1,
  spaceBetween: 12,
  initialSlide: 1,

  // Allows to set different parameter for different responsive breakpoints (screen sizes)
  breakpoints: {
    // // when window width is >= 360px
    360: {
      slidesPerView: 1.6,
      spaceBetween: 20,
      centeredSlides: false,
      initialSlide: 1,
    },
    // when window width is >= 576px
    576: {
      slidesPerView: 3,
      spaceBetween: 20,
      centeredSlides: false,
      initialSlide: 1,
    },
    // when window width is >= 1200px
    1200: {
      slidesPerView: 4,
      spaceBetween: 20,
      centeredSlides: false,
      initialSlide: 1,
    },
  },
});

new Swiper(".mobile__details__thumbnail__main", {
  spaceBetween: 8,
  navigation: {
    nextEl: ".swiper-button-next",
    prevEl: ".swiper-button-prev",
  },
  pagination: {
    el: ".swiper-pagination",
    clickable: true,
  },
});

// blog slider
new Swiper(".slider__blogs .swiper-container", {
  slidesPerView: 1,
  spaceBetween: 12,
  initialSlide: 1,

  // Allows to set different parameter for different responsive breakpoints (screen sizes)
  breakpoints: {
    // // when window width is >= 360px
    360: {
      slidesPerView: 2.2,
      spaceBetween: 20,
      centeredSlides: false,
      initialSlide: 1,
    },
    // when window width is >= 576px
    576: {
      slidesPerView: 3,
      spaceBetween: 20,
      centeredSlides: false,
      initialSlide: 1,
    },
    // when window width is >= 1200px
    1200: {
      slidesPerView: 4,
      spaceBetween: 20,
      centeredSlides: false,
      initialSlide: 1,
    },
  },
});

//reviews
new Swiper('.slider__reviews .swiper-container', {
  slidesPerView: 1,
  spaceBetween: 12,
  initialSlide: 1,

  // Allows to set different parameter for different responsive breakpoints (screen sizes)
  breakpoints: {
    // // when window width is >= 360px
    360: {
      slidesPerView: 1.5,
      spaceBetween: 20,
      centeredSlides: false,
      initialSlide: 1,
    },
    // when window width is >= 576px
    576: {
      slidesPerView: 2,
      spaceBetween: 20,
      centeredSlides: false,
      initialSlide: 1,
    },
    // when window width is >= 1200px
    1200: {
      slidesPerView: 4,
      spaceBetween: 20,
      centeredSlides: false,
      initialSlide: 1,
    },
  },
});

