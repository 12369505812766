window._ = require("lodash");

window.$ = window.jQuery = require("jquery");

window.bootstrap = require("bootstrap");
import Alpine from "alpinejs";
window.Alpine = Alpine;

//alpine components
import "./components/search";

Alpine.start();

import axios from "axios";
window.axios = axios;

window.axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";
