function unsecuredCopyToClipboard(text) {
    const textArea = document.createElement("textarea");
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.focus({preventScroll:true});
    textArea.select();
    try {
        document.execCommand('copy');
    } catch (err) {
        console.error('Unable to copy to clipboard', err);
    }
    document.body.removeChild(textArea);
}
function notificationSuccessCopy() {
    const customAlert = document.querySelector('.custom-alert-notification');
    customAlert.classList.remove('d-none');
    setTimeout( () => {
        customAlert.classList.add('d-none');
    }, 2000)
}

$(document).ready(function () {
    document.querySelectorAll('.href-button').forEach((button) => {
        button.addEventListener('click', function (e) {
            const id = e.currentTarget.dataset.id
            const copyText = document.querySelector("#urlInput" + id);

            if (navigator.clipboard) {
                navigator.clipboard.writeText(copyText.value).then( () => {
                    notificationSuccessCopy();
                });

            } else {
                unsecuredCopyToClipboard(copyText.value);
                notificationSuccessCopy();
            }
        });
    });
});