import "./bootstrap";
import "./components/loader";
import "./ProductDetail";
import "./SellProductStepPrice";
import "./userDashboard";

//vendors
import "./modules/swiper";
import "./modules/notyf";

//for tooltip
var tooltipTriggerList = [].slice.call(
  document.querySelectorAll('[data-bs-toggle="tooltip"]')
);
tooltipTriggerList.map(function (tooltipTriggerEl) {
  return new window.bootstrap.Tooltip(tooltipTriggerEl);
});
