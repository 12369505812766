import { loadStripe } from "@stripe/stripe-js";
let ProductDetail = {
  init: async function () {
    const checkoutButton = document.querySelector("#buyNowDetailForm");
    const stripe = await loadStripe(process.env.MIX_STRIPE_PUBLIC_KEY);
    let checkoutUrl = null;
    let bidInput = document.querySelector(".bid-price");
    let buyNowInput = document.querySelector("#buyNowInput");
    let productId = $('input[name="product_id"]').val();

    let productUrl = "";
    const stateSelectionInputs = document.querySelectorAll(".state-selection");
    stateSelectionInputs.forEach((input) => {
      input.addEventListener("click", stateSelect);
    });

    if (checkoutButton) {
      checkoutButton.addEventListener("click", (e) => {
        if (checkoutUrl) {
          parent.location.href = checkoutUrl;
        }
      });
    }
    let bidChecking = false;

    $("body").on("click", '#product_form input[type="radio"]', checkBid);

    function checkBid(e, state) {
      if (bidChecking) {
        return;
      }
      bidChecking = true;
      let productForm = document.getElementById("product_form");
      let formData = new FormData(productForm);
      let additionalParams = null;
      if (e?.target.hasAttribute("data-order")) {
        const currentInput = $(this)[0];
        let paramName = currentInput.name;
        let paramValue = currentInput.defaultValue;
        let order = currentInput.dataset.order;
        if (order === "1" || order === "2") {
          clearFormData(order);
          productForm = document.getElementById("product_form");
          formData = new FormData(productForm);
        }
        let currentParam = { name: paramName, order: order };
        let filterParams = { [paramName]: { value: paramValue, order: order } };
        additionalParams = {
          newBid: 0,
          product_id: productId,
          filterParams: filterParams,
          currentParam: currentParam,
        };
      } else {
        additionalParams = {
          newBid: 0,
          product_id: productId,
          state_switch: true,
          state: state,
        };
      }
      const requestData = {
        ...Object.fromEntries(formData),
        ...additionalParams,
      };
      showLoader();
      $.get("/attributes/product/" + productId, requestData, function (data) {
        if (data.success) {
          hideLoader();
          if (data.product_image) {
            $(".mobile__details__thumbnails .swiper-slide-active img").attr('src', data.product_image);
          }
          document.querySelector(".detail-form").innerHTML = data.html;

          document.querySelectorAll(".state-selection").forEach((input) => {
            input.addEventListener("click", stateSelect);
          });
          document.querySelectorAll(".tooltip").forEach((tooltip) => {
            tooltip.classList.remove("show");
          });
          const toolTips = document.querySelectorAll(
            '.detail-form [data-bs-toggle="tooltip"]'
          );
          [...toolTips].map((tooltipTriggerEl) => {
            new bootstrap.Tooltip(tooltipTriggerEl);
          });
          productUrl = data.productUrl;
          if (data && data.newProductId) {
            productId = data.newProductId;
            $("input[name=product_id]").val(productId);
            document.querySelector("#bidProductId").value = productId;
          }

          $('input[name="price"]').val(data.bid.highest + 1);
          checkoutUrl = data.productUrl;
          if (data.ask.lowest === 0) {
            $(".btn-buy").attr("disabled", true);
            document.querySelector('.tab-2').classList.add('disabled');
          } else {
            $(".btn-buy").removeAttr("disabled");
            document.querySelector('.tab-2').classList.remove('disabled');
            buyNowInput.value = data.ask.lowest;
          }
          $("#lowest-ask").html(data.ask.lowest);
          $("#modal-lowest-ask").html(data.ask.lowest);
          $("#modal-highest-bid").html(data.bid.highest);
          $("#modal-lowest-bid").html(data.bid.lowest);

          bidInput.value = data.bid.highest + 1;
          if (data.bid.highest === 0 && data.ask.lowest !== 0) {
            bidInput.value = data.ask.lowest - 1;
          }
          bidChecking = false;
          $("#buy-now-btn").html(data.buy_now_btn);
        }
      });
    }

    function stateSelect(e) {
      let stateSelectionDivs = document.querySelectorAll(
        ".state-selection-div"
      );
      let guaranteeDivs = document.querySelectorAll(".guarantee-div");
      let inputNew = document.querySelector("#stateNew");
      let inputUsed = document.querySelector("#stateUsed");
      let state = "new";
      if (e.target.dataset.state === "used") {
        state = "used";
        stateSelectionDivs.forEach((div) => {
          div.removeAttribute("hidden");
        });
        guaranteeDivs.forEach((div) => {
          div.removeAttribute("hidden");
        });
        inputNew.removeAttribute("checked");
        inputUsed.setAttribute("checked", "checked");

        document
          .querySelectorAll('#product_form input[name="front_state"]')
          .forEach((input) => {
            if (input.hasAttribute("checked")) {
              input.removeAttribute("checked");
            }
          });
        document
          .querySelectorAll('#product_form input[name="back_state"]')
          .forEach((input) => {
            if (input.hasAttribute("checked")) {
              input.removeAttribute("checked");
            }
          });
        document
          .querySelectorAll('#product_form input[name="battery"]')
          .forEach((input) => {
            if (input.hasAttribute("checked")) {
              input.removeAttribute("checked");
            }
          });
        document
          .querySelectorAll('#product_form input[name="guarantee"]')
          .forEach((input) => {
            if (input.hasAttribute("checked")) {
              input.removeAttribute("checked");
            }
          });
      } else if (e.target.dataset.state === "new") {
        guaranteeDivs.forEach((div) => {
          if (div.querySelector("input").value !== "12+") {
            div.setAttribute("hidden", true);
          }
        });
        document
          .querySelectorAll('#product_form input[name="front_state"]')
          .forEach((input) => {
            if (input.value === "new") {
              input.setAttribute("checked", "checked");
            }
          });
        document
          .querySelectorAll('#product_form input[name="back_state"]')
          .forEach((input) => {
            if (input.value === "new") {
              input.setAttribute("checked", "checked");
            }
          });
        document
          .querySelectorAll('#product_form input[name="battery"]')
          .forEach((input) => {
            if (input.value === "100") {
              input.setAttribute("checked", "checked");
            }
          });
        document
          .querySelectorAll('#product_form input[name="guarantee"]')
          .forEach((input) => {
            if (input.value === "12+") {
              input.setAttribute("checked", "checked");
            }
          });
        stateSelectionDivs.forEach((div) => {
          div.setAttribute("hidden", true);
        });
        inputUsed.removeAttribute("checked");
        inputNew.setAttribute("checked", "checked");
      }
      checkBid(e, state);
    }

    $("#product-show-buyers").on("show.bs.modal", loadProductData);
    $("#product-show-sellers").on("show.bs.modal", loadProductData);

    function loadProductData() {
      const $contentContainer = $(this).find(".content-data");
      const $loader = $(this).find(".spinner-border");
      const params = {};
      const formData = new FormData(document.getElementById("product_form"));
      formData.forEach((value, key) => (params[key] = value));
      $contentContainer.html("");
      $loader.show();

      $.get($(this).data("url"), params, function (data) {
        if (data.content) {
          $contentContainer.html(data.content);
          $loader.hide();
        }
      });
    }

    function clearFormData(order) {
      document
        .querySelectorAll('#product_form input[name="front_state"]')
        .forEach((input) => {
          if (input.hasAttribute("checked")) {
            input.removeAttribute("checked");
          }
        });
      document
        .querySelectorAll('#product_form input[name="back_state"]')
        .forEach((input) => {
          if (input.hasAttribute("checked")) {
            input.removeAttribute("checked");
          }
        });

      document
        .querySelectorAll('#product_form input[name="battery"]')
        .forEach((input) => {
          if (input.hasAttribute("checked")) {
            input.removeAttribute("checked");
          }
        });

      document
        .querySelectorAll('#product_form input[name="guarantee"]')
        .forEach((input) => {
          if (input.hasAttribute("checked")) {
            input.removeAttribute("checked");
          }
        });

      if (order === "1") {
        document
          .querySelectorAll('#product_form input[name="memory_size"]')
          .forEach((input) => {
            if (input.hasAttribute("checked")) {
              input.removeAttribute("checked");
            }
          });
      }
    }

    (function durationModal() {
      const oneWeekInMs = 604800000;
      const twoWeekInMs = 1209600000;
      const threeWeekInMs = 1814400000;
      const durationSelector = document.querySelector(".duration-selector");
      durationSelector.addEventListener("change", (event) => {
        let durationDate = 0;
        let selectedValue = event.target.value;
        if (selectedValue === "1") {
          durationDate = new Date().getTime() + oneWeekInMs;
        } else if (selectedValue === "2") {
          durationDate = new Date().getTime() + twoWeekInMs;
        } else if (selectedValue === "3") {
          durationDate = new Date().getTime() + threeWeekInMs;
        }
        const durationText = document.querySelector(".duration-text");
        durationText.innerHTML = `Your offer is valid till ${new Date(durationDate).toLocaleString("de-DE").split(",")[0]
          }`;
      });

      // Select the input field by its name attribute or other suitable selector
      const csrfToken = $('input[name="_token"]').val();
      let resultMessage = $("#result-message"); // Select the result message element
      let inputBid = $('input[name="price"]');
      const bidFrom = document.querySelector(".bid-form");
      const tab2 = $('.tab-2');
      const paymentMethodInput = document.querySelectorAll('input[name="payment_method"]');
      let elements = null;
      let stripeElementPaymentType = null;
      const clientSecret = document.querySelector('input[name="client_secret"]').value;
      const intent = document.querySelector('input[name="intent"]').value;
      if (clientSecret) {
        elements = stripe.elements({ clientSecret: clientSecret });
        const cardElement = elements.create('payment', {
          hidePostalCode: true,
          style: {
            base: {
              iconColor: '#666EE8',
              color: '#31325F',
              lineHeight: '40px',
              fontWeight: 300,
              fontFamily: 'Helvetica Neue',
              fontSize: '15px',
              '::placeholder': {
                color: '#CFD7E0',
              },
            },
          }
        });
        paymentMethodInput.forEach((input) => {
          input.addEventListener('change', async (event) => {
            if (event.target.value === 'other') {
              document.querySelector('#credit-cards').hidden = true
              cardElement.mount('#card-element');
            } else {
              document.querySelector('#credit-cards').hidden = false
              cardElement.unmount();
            }
          });
        });
        cardElement.on('change', (event) => {
          stripeElementPaymentType = event.value.type;
        });
      }
      inputBid.on("input", function (e) {
        const productForm = document.getElementById("product_form");
        let productId = document.querySelector("#bidProductId").value;
        // Get the new bid value entered by the user
        let newBid = e.target.value.replace(/[^0-9]+/g, "");
        $(this).val(newBid);

        const formData = new FormData(productForm);
        const additionalParams = {
          newBid: newBid,
          product_id: productId,
        };

        // Combine form data and additional parameters
        const requestFormData = {
          ...Object.fromEntries(formData),
          ...additionalParams,
        };

        let requestData = JSON.stringify(requestFormData);
        // Make an API call to check if the bid is higher than the highest bid and meets the lowest ask
        $.ajax({
          url: "/check-bid",
          method: "POST", // Change the HTTP method as needed
          headers: {
            "Content-Type": "application/json",
            "X-CSRF-TOKEN": csrfToken, // Include the CSRF token in the headers
          },
          data: requestData,
          success: function (response) {
            // Handle the API response here
            if (response && response.success) {
              //submitButton.prop('disabled', false);
            } else {
              // submitButton.prop('disabled', true);
              resultMessage.removeClass("text-success");
              resultMessage.addClass("text-danger");
            }
            if (
              response &&
              response.newProductId &&
              response.newProductId !== response.product_id
            ) {
              productId = response.newProductId;
              $("input[name=product_id]").val(response.newProductId);
              document.querySelector("#checkout-link").href =
                response.productUrl;
              $("#buyNowInput").val(response.ask.lowest);
              document.querySelector("#modal-lowest-ask").innerHTML =
                response.ask.lowest;
            }

            if (response && response.meetsLowestAsk) {
              tab2.removeClass('disabled');
              $("label[for=buy-now]").trigger('click');
              document.querySelector('.bid-price').value = "";
            }

            if (response && response.meetsLowestAsk) {
              document.querySelector(".bid-price").value = "";
            }
            if (response && response.message) {
              let classText = "text-success";
              if (response.class && response.class.length > 0) {
                classText = "text-" + response.class;
              }
              resultMessage.removeClass("text-success");
              resultMessage.removeClass("text-danger");
              resultMessage.addClass(classText);

              resultMessage.text(response.message);

              $(".total-payout").text(response.fee.total + ' €');
              $(".product-fee").text(response.fee.price + ' €');
              $(".service-fee").text(response.fee.serviceFee + ' €');
              $(".shipping-fee").text(response.fee.shippingFee + ' €');
            }
          },
          error: function (error) {
            console.log("error", error);
            resultMessage.addClass("text-danger");
            resultMessage.text(error.responseJSON.message);
          },
        });
      });

      bidFrom.addEventListener("submit", async (event) => {
        event.preventDefault();
        $("#create-bid").attr('disabled', true);
        const productForm = document.getElementById("product_form");
        let productId = document.querySelector("#bidProductId").value;
        let newBid = parseFloat(inputBid.val());
        let duration = document.querySelector(".duration-selector").value;
        const formData = new FormData(productForm);
        document
          .querySelectorAll('input[name="payment_method"]')
          .forEach((input) => {
            if (input.checked) {
              formData.append(input.name, input.value);
            }
            formData.append('intent', intent);
            formData.append('client_secret', clientSecret);
            formData.append('stripeElementPaymentType', stripeElementPaymentType);
          });
        document.querySelectorAll('input[name="payment_method_id"]').forEach((input) => {
          if (input.checked) {
            formData.append(input.name, input.value)
          }
        });
        const additionalParams = {
          price: newBid,
          product_id: productId,
          api_response: 1,
          type: "Bid",
          duration: duration,
        };
        const checkBidParams = {
          newBid: newBid,
          product_id: productId,
        };
        // Combine form data and additional parameters
        const requestFormData = {
          ...Object.fromEntries(formData),
          ...additionalParams,
        };
        const requestCheckBidFormData = {
          ...Object.fromEntries(formData),
          ...checkBidParams,
        };
        let requestData = JSON.stringify(requestCheckBidFormData);
        $.ajax({
          url: "/check-bid",
          method: "POST", // Change the HTTP method as needed
          headers: {
            "Content-Type": "application/json",
            "X-CSRF-TOKEN": csrfToken, // Include the CSRF token in the headers
          },
          data: requestData,
          success: function (response) {
            if (response && response.meetsLowestAsk) {
              Swal.fire({
                text:
                  "We found a suitable ask with a price " + response.ask.lowest,
                icon: "success",
                buttonsStyling: false,
                confirmButtonText: "Ok, got it!",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
              }).then(function (result) {
                productId = response.newProductId;
                $("input[name=product_id]").val(response.newProductId);
                const checkoutLink = document.querySelector("#checkout-link");
                checkoutLink.href = response.productUrl;
                $("#buyNowInput").val(response.ask.lowest);
                checkoutLink.click();
                $("#create-bid").attr('disabled', false);
              });
            } else {
              fetch("/offers", {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                  "X-CSRF-TOKEN": csrfToken, // Include the CSRF token in the headers
                },
                body: JSON.stringify(requestFormData),
              })
                .then((response) => response.json())
                .then(async (data) => {
                  if (data.success && data.payment_method === 'other') {
                    const { error } = await stripe.confirmPayment({
                      elements,
                      confirmParams: {
                        return_url: appUrl + '/bid/creating/finish',
                      },
                    });
                    if (error) {
                      console.log(error)
                    }
                  } else if (data.errors) {
                    $.each(data.errors, function (field, messages) {
                      window.notyf.error(messages[0]);
                    })
                  } else {
                    window.location.href = '/bid/creating/finish';
                  }
                  $("#create-bid").attr('disabled', false);
                })
                .catch((error) => {
                  console.log("Error during fetch:", error);
                });
            }
          },
          error: function (error) {
            console.log("error", error);
            resultMessage.addClass("text-danger");
            resultMessage.text(error.responseJSON.message);
          },
        });
      });
    })();
    checkBid();
  },
};

window.loginRedirect = () => {
  parent.location.href = "/sign-in?referer=" + window.location;
};

$(function () {
  if (document.querySelector("#product-detail-page")) {
    window.ProductDetail = ProductDetail;
    window.ProductDetail.init();
  }
});
